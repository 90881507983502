<template>
  <div style="display: contents">
    <BlockPageHeader
      v-if="showHeader"
      :page="page"
      :users="users"
      :show-title="showTitle"
      :version="currentVersion"
      :show-preview-button="showPreviewButton"
      :paddingless="false"
      @sidebar="$emit('sidebar', $event)"
      @open="$emit('open', $event)"
      @preview="$emit('preview')"
      @edit="$emit('edit', page)"
    />
    <PanelPagesContentTranslator
      v-if="translator && currentVersion && !isOriginal"
      :key="`${versionKey}translator${currentVersion.state}`"
      :ref="`page_content_${versionKey}`"
      :page-key="pageKey"
      :page="page"
      :show-title="showTitle"
      :version-key="versionKey"
      :version="currentVersion"
      :original-key="originalKey"
      :permissions="permissions"
      :users="users"
      :comments-document="comments['document']"
      :comments="comments"
      :active-block="activeBlock"
      @sidebar="$emit('sidebar', $event)"
      class="main-editor min-h-full max-h-full"
      :class="{
        rtl: isRTL
      }"
      v-on="$listeners"
    />
    <PanelPagesContentEditor
      v-else-if="page && currentVersion"
      :key="`${versionKey}editor${currentVersion.state}`"
      :ref="`page_content_${versionKey}`"
      class="main-editor min-h-full max-h-full w-full pl-5 pr-7"
      :class="{
        rtl: isRTL
      }"
      wide
      paddingless
      :page-key="pageKey"
      :show-title="false"
      :page="page"
      :show-chapters="showChapters"
      :version="currentVersion"
      :permissions="permissions"
      :users-editing="usersEditing"
      :users="users"
      :active-block="activeBlock"
      :comments-document="comments['document']"
      :format-options="formatOptions"
      :editor-blocks="editorBlocks"
      :comments="comments"
      :show-comments-highlight="showCommentsHighlight"
      @sidebar="$emit('sidebar', $event)"
      v-on="$listeners"
    ></PanelPagesContentEditor>
  </div>
</template>

<script>
export default {
  components: {
    PanelPagesContentTranslator: () =>
      import('@/fw-modules/fw-core-vue/pages/components/panels/PanelPagesContentTranslator.vue'),
    PanelPagesContentEditor: () =>
      import('@/fw-modules/fw-core-vue/pages/components/panels/PanelPagesContentEditor.vue'),
    BlockPageHeader: () => import('@/fw-modules/fw-core-vue/pages/components/blocks/BlockPageHeader.vue')
  },
  props: {
    pageKey: {
      type: String,
      required: true
    },
    isRTL: {
      type: Boolean,
      default: false
    },
    showChapters: {
      type: Boolean,
      default: false
    },
    page: {
      type: Object,
      required: true
    },
    currentVersion: {
      type: Object,
      required: true
    },
    versionKey: {
      type: String,
      required: true
    },
    permissions: {
      type: Object,
      required: true
    },
    originalKey: {
      type: String,
      required: true
    },
    usersEditing: {
      type: Object,
      required: true
    },
    users: {
      type: Object,
      required: true
    },
    activeBlock: {
      type: Object,
      default: () => {}
    },
    comments: {
      type: Object,
      required: true
    },
    /*translator: {
      type: Boolean,
      default: false
    },*/
    showTitle: {
      type: Boolean,
      default: false
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    formatOptions: {
      type: Array,
      default: () => [
        'bold',
        'italic',
        'underline',
        'strike',
        'link',
        'blockquote',
        'code',
        'codeBlock',
        'bulletList',
        'orderedList',
        'heading',
        'textAlign',
        'highlight',
        'small'
      ]
    },
    editorBlocks: {
      type: Array,
      default: () => [
        'heading',
        'image',
        'files',
        'video',
        'youtube',
        'blockquote',
        'codeBlock',
        'bulletList',
        'orderedList',
        'files',
        'horizontalRule'
      ]
    },
    showCommentsHighlight: {
      type: Boolean,
      default: false
    },
    showPreviewButton: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    setCommentsNumber(nodeId, commentsNumber) {
      if (this.$refs[`page_content_${this.versionKey}`]) {
        this.$refs[`page_content_${this.versionKey}`].setCommentsNumber(nodeId, commentsNumber)
      }
    },
    focusEditorNode(nodeId) {
      if (this.$refs[`page_content_${this.versionKey}`]) {
        this.$refs[`page_content_${this.versionKey}`].focusEditorNode(nodeId)
      }
    }
  },
  computed: {
    translator() {
      return process.env.VUE_APP_KEY == 'mondaecus'
    },
    isOriginal() {
      return this.currentVersion.is_original
    }
  }
}
</script>
